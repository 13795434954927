/** @format */

import React from "react";
import IconBoxBrisbane from "../../components/Cities/Brisbane/IconBoxBrisbane";
import HeroSectionBrisbane from "../../components/Cities/Brisbane/HeroSectionBrisbane";
import PreviousWork from "../../components/Cities/Brisbane/PreviousWork";
import AboutBrisbane from "../../components/Cities/Brisbane/AboutBrisbane";

const Brisbane = () => {
  return (
    <div>
      <HeroSectionBrisbane />
      <IconBoxBrisbane />
      <PreviousWork />
      <AboutBrisbane />
    </div>
  );
};

export default Brisbane;
