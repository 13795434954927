/** @format */

import React from "react";
import { MessageCircle, ThumbsUp } from "lucide-react";
import { Link } from "react-router-dom";

const ServiceCard = ({ title, description, imageSrc }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden">
    <div className="relative">
      <img src={imageSrc} alt={title} className="w-full h-48 object-cover" />
      <div className="absolute bottom-2 left-2 flex space-x-2">
        <div className="bg-white rounded-full p-1">
          <MessageCircle className="w-5 h-5 text-gray-600" />
        </div>
        <div className="bg-white rounded-full p-1">
          <ThumbsUp className="w-5 h-5 text-gray-600" />
        </div>
      </div>
    </div>
    <div className="p-4 border-l-4 border-red-500">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600 mb-4">{description}</p>
      <Link to="/contact">
        <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300">
          Get Free Quote
        </button>
      </Link>
    </div>
  </div>
);

export default function Satisfaction() {
  const services = [
    {
      title: "Cover Replacement",
      description:
        "We specialize in replacing and repairing car side mirrors. Our cover replacement service involves carefully removing the old cover and securely attaching a new one.",
      imageSrc:
        "https://sidecarmirrors.com.au/wp-content/uploads/2024/10/Trim_new-min.jpg",
    },
    {
      title: "Full Unit Replacement",
      description:
        "Our full unit replacement service includes removing the damaged mirror assembly and installing a brand new unit, ensuring perfect fit and functionality.",
      imageSrc:
        "https://sidecarmirrors.com.au/wp-content/uploads/2024/10/Cover-Replacement_new-min-1.jpg",
    },
    {
      title: "Glass Replacement",
      description:
        "Our glass replacement service involves carefully removing the broken or damaged mirror glass and installing a new one, ensuring clear visibility and perfect fit.",
      imageSrc:
        "https://sidecarmirrors.com.au/wp-content/uploads/2024/10/Glass-Replacement_new-min.jpg",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center mb-2">
        Our service <span className="text-red-500">your satisfaction</span>
      </h1>
      <p className="text-center text-gray-600 mb-8">
        Insert and customize your Elementor Image box with the left border and
        display it to your wordpress sites.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>
    </div>
  );
}
