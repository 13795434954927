/** @format */

import { DollarSign, Wrench, ArrowLeftRight, Phone } from "lucide-react";

const FeatureCard = ({ icon: Icon, title, subtitle }) => (
  <div className="bg-gradient-to-br from-red-600 to-red-900 rounded-3xl p-6 flex flex-col items-center text-center text-white">
    <Icon className="w-12 h-12 mb-4" />
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-sm">{subtitle}</p>
  </div>
);

export default function IconBoxSydney() {
  const features = [
    {
      icon: DollarSign,
      title: "Best Price Guarantee",
      subtitle: "Unbelievable pricing",
    },
    {
      icon: Wrench,
      title: "Same/Next Day Repair",
      subtitle: "Fast and Efficient",
    },
    {
      icon: ArrowLeftRight,
      title: "Free Installation (We come to you)",
      subtitle: "Convenience at its best",
    },
    {
      icon: Phone,
      title: "24/7 Customer Support",
      subtitle: "Available to you",
    },
  ];

  return (
    <div className="max-w-[1450px] mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </div>
  );
}
