/** @format */

import React from "react";
import { DollarSign, Users } from "lucide-react";

export default function AboutBrisbane() {
  return (
    <div className="mx-auto px-4 py-2 max-w-[1400px]">
      <div className="flex flex-col md:flex-row items-center gap-8">
        <div className="md:w-1/2">
          <img
            src="https://sidecarmirrors.com.au/wp-content/uploads/2024/09/autozone-img12.png"
            alt="Red sports car"
            className="w-full h-auto rounded-lg "
          />
        </div>
        <div className="md:w-1/2">
          <h1 className="text-4xl font-bold mb-4 text-gray-800">
            Side Mirror Replacement and Repair in Brisbane
          </h1>
          <p className="text-lg mb-6 text-gray-600">
            Side mirror replacement and repair in Brisbane. We offer great value
            and service at your door step with free installation.
          </p>
          <div className="space-y-6">
            <div className="flex items-start gap-4 bg-white shadow-md p-5 rounded-md">
              <div className="bg-red-500 p-3 rounded-full">
                <DollarSign className="w-6 h-6 text-white" />
              </div>
              <div>
                <h2 className="text-xl font-semibold mb-2 text-gray-800">
                  Best Affordable Price
                </h2>
                <p className="text-gray-600">
                  We offer affordable pricing for side car mirror replacements
                  and include free installation. You get great value and
                  convenience with our services.
                </p>
              </div>
            </div>
            <div className="flex items-start gap-4 bg-white shadow-md p-5 rounded-md">
              <div className="bg-blue-800 p-3 rounded-full">
                <Users className="w-6 h-6 text-white" />
              </div>
              <div>
                <h2 className="text-xl font-semibold mb-2 text-gray-800">
                  Qualified Staff & Technician
                </h2>
                <p className="text-gray-600">
                  Our skilled staff and technicians ensure top-quality side
                  mirror replacement and repair. With their expertise, you can
                  trust that your service will be done right.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white p-6 rounded-lg shadow-md my-20">
        <div className="flex items-center space-x-4">
          <div className="w-1 h-12 bg-red-600"></div>
          <div className="flex-grow">
            <h3 className="text-2xl font-bold mb-2">
              Can't find what you're looking for?
            </h3>
            <p className="text-gray-600">
              If you can't find what you're looking for, don't worry – we're
              here to help! Simply click the button below, <br></br> and we'll
              get back to you promptly to assist you with your needs.
            </p>
          </div>
          <button className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 transition-colors">
            Get Free Quote{" "}
          </button>
        </div>
      </section>
    </div>
  );
}
