/** @format */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Columns2, Search, X } from "lucide-react";
import productsData from "../data/products.json";
import categoriesData from "../data/categories.json";
import ProductCard from "../components/Products/ProductCard";
import MakeModelYearHorizontal from "../components/MMY/MakeModelYearHorizontal";

export default function ShopPage() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const make = query.get("make");
  const model = query.get("model");
  const year = query.get("year");

  useEffect(() => {
    let results = productsData;

    if (make) results = results.filter((p) => p.make === make);
    if (model) results = results.filter((p) => p.model === model);
    if (year) results = results.filter((p) => p.year === year);
    if (selectedCategories.length)
      results = results.filter((p) => selectedCategories.includes(p.category));
    if (searchTerm)
      results = results.filter((p) =>
        p.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

    setFilteredProducts(results);
  }, [make, model, year, selectedCategories, searchTerm]);

  useEffect(() => {
    setCategories(categoriesData);
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const FilterContent = () => (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">Filters</h2>
      <MakeModelYearHorizontal />
      <div className="my-4 border-t border-gray-200 pt-4">
        <h3 className="font-semibold mb-2">Category</h3>
        <div className="flex flex-wrap gap-2">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => handleCategoryChange(category.name)}
              className={`px-3 py-1 rounded-full text-sm ${
                selectedCategories.includes(category.name)
                  ? "bg-red-600 text-white"
                  : "bg-gray-200 text-gray-800 hover:bg-gray-300"
              }`}>
              {category.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen pb-20 bg-white">
      <div className="relative h-64 md:h-80 overflow-hidden mb-8">
        <img
          src="https://img.freepik.com/free-photo/detailed-shot-car-wheels-tires_157027-4311.jpg?w=1380"
          alt="Car wheels and tires"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white text-center shadow-text">
            Shop Our Products
          </h1>
        </div>
      </div>

      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Filters */}
          <div
            className={`md:w-1/4 ${
              isFilterOpen ? "block" : "hidden md:block"
            }`}>
            <div className="bg-white rounded-lg shadow-md">
              <FilterContent />
            </div>
          </div>

          {/* Products */}
          <div className="md:w-3/4">
            <div className="mb-6 flex items-center">
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Search products"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:bg-gray-300"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              </div>
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="ml-4 p-2 bg-red-600 text-white rounded-lg md:hidden">
                {isFilterOpen ? (
                  <X className="w-6 h-6" />
                ) : (
                  <Columns2 className="w-6 h-6" />
                )}
              </button>
            </div>

            {filteredProducts.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredProducts.map((product) => (
                  <ProductCard key={product.id} product={product} />
                ))}
              </div>
            ) : (
              <div className="text-center py-12">
                <h2 className="text-2xl font-semibold mb-2">
                  No products found
                </h2>
                <p className="text-gray-600">
                  Try adjusting your filters or search term
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
