/** @format */

import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  Button,
  Box,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import categoriesData from "../../data/categories.json";
import productsData from "../../data/products.json";

const MakeModelYear = () => {
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (make) {
      const uniqueModels = [
        ...new Set(
          productsData
            .filter((product) => product.make === make)
            .map((p) => p.model)
        ),
      ];
      setModels(uniqueModels);
    } else {
      setModels([]);
      setModel("");
      setYear("");
    }
  }, [make]);

  useEffect(() => {
    if (model) {
      const uniqueYears = [
        ...new Set(
          productsData
            .filter((product) => product.model === model)
            .map((product) => product.year)
        ),
      ].sort((a, b) => b - a);
      setYears(uniqueYears);
    } else {
      setYears([]);
      setYear("");
    }
  }, [model]);

  const handleSearch = () => {
    navigate(`/shop?make=${make}&model=${model}&year=${year}`);
  };

  return (
    <Container>
      <Box sx={{ mt: 5, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Make Field */}
          <Grid item xs={12} sm={4}>
            <Select
              value={make}
              onChange={(e) => setMake(e.target.value)}
              displayEmpty
              fullWidth
              sx={{
                mb: 2,
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                "& .MuiSelect-select": {
                  padding: "10px",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                },
              }}>
              <MenuItem value="" disabled>
                Select Make
              </MenuItem>
              {categoriesData.map((category) => (
                <MenuItem key={category.id} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Model Field */}
          <Grid item xs={12} sm={4}>
            <Select
              value={model}
              onChange={(e) => setModel(e.target.value)}
              displayEmpty
              fullWidth
              disabled={!make}
              sx={{
                mb: 2,
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                "& .MuiSelect-select": {
                  padding: "10px",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                },
              }}>
              <MenuItem value="" disabled>
                Select Model
              </MenuItem>
              {models.map((m) => (
                <MenuItem key={m} value={m}>
                  {m}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Year Field */}
          <Grid item xs={12} sm={4}>
            <Select
              value={year}
              onChange={(e) => setYear(e.target.value)}
              displayEmpty
              fullWidth
              disabled={!model}
              sx={{
                mb: 2,
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                "& .MuiSelect-select": {
                  padding: "10px",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                },
              }}>
              <MenuItem value="" disabled>
                Select Year
              </MenuItem>
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        {/* Search Button */}
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Button
            variant="contained"
            onClick={handleSearch}
            disabled={!year}
            sx={{
              bgcolor: "#dc2626",
              color: "#fff",
              borderRadius: "8px",
              px: { xs: 2, sm: 4 },
              py: { xs: 1, sm: 1.5 },
              "&:hover": {
                bgcolor: "#000",
              },
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}>
            Search
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default MakeModelYear;
