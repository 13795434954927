/** @format */

import React from "react";
import HeroSectionGoldCoast from "../../components/Cities/GoldCoast/HeroSectionGoldCoast";
import IconBoxGoldCoast from "../../components/Cities/GoldCoast/IconBoxGoldCoast";
import PreviousWorkGoldCoast from "../../components/Cities/GoldCoast/PreviousWorkGoldCoast";
import AboutGoldCoast from "../../components/Cities/GoldCoast/AboutGoldCoast";

const GoldCoast = () => {
  return (
    <div>
      <HeroSectionGoldCoast />
      <IconBoxGoldCoast />
      <PreviousWorkGoldCoast />
      <AboutGoldCoast />
    </div>
  );
};

export default GoldCoast;
