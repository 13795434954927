/** @format */

import React from "react";

export default function Component() {
  return (
    <div className="bg-[#f0f8ff] mb-10">
      <div className="max-w-[1400px] mx-auto p-8 space-y-12">
        <section className="text-center space-y-4">
          <p className="text-sm text-gray-600 uppercase">
            TO MEET YOUR EXPECTATION
          </p>
          <h2 className="text-4xl font-bold">
            How We <span className="text-red-600">Works</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-8">
            {[
              {
                number: "01",
                title: "Identify the Issues",
                description:
                  "Identify issues with your side car mirror, such as cracks, malfunctions, or faulty indicators. Our service helps pinpoint problems to ensure accurate replacement and repair.",
              },
              {
                number: "02",
                title: "Select the Ideal Mirror",
                description:
                  "Select the ideal mirror by matching it to your vehicle's make and model. Consider features like manual or electric adjustment, heating, and built-in sensors to ensure it meets your specific needs.",
              },
              {
                number: "03",
                title: "Installation & Replacement",
                description:
                  "We provide free installation right at your doorstep, making it even more convenient. Our service guarantees a hassle-free experience with no extra costs for installation.",
              },
              {
                number: "04",
                title: "Efficient & Cost Effective Solutions",
                description:
                  "Our side mirror replacement and repair services are designed to be both efficient and budget-friendly. You get high-quality work at affordable prices, with free installation included for added value.",
              },
            ].map((step, index) => (
              <div key={index} className="space-y-2">
                <div className="text-6xl font-bold">{step.number}</div>
                <h3 className="text-xl font-semibold">{step.title}</h3>
                <p className="text-sm text-gray-600">{step.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex items-center space-x-4">
            <div className="w-1 h-12 bg-red-600"></div>
            <div className="flex-grow">
              <h3 className="text-2xl font-bold mb-2">
                Can't find what you're looking for?
              </h3>
              <p className="text-gray-600">
                If you can't find what you're looking for, don't worry – we're
                here to help! Simply click the button below, <br></br> and we'll
                get back to you promptly to assist you with your needs.
              </p>
            </div>
            <button className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 transition-colors">
              Get Free Quote{" "}
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}
