/** @format */

import React from "react";
import Register from "./Register";

const MyAccount = () => {
  return (
    <div>
      <Register />
    </div>
  );
};

export default MyAccount;
