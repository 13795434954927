/** @format */

import React from "react";

const brands = [
  {
    name: "Subaru",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Saubaru-N-logo.jpg",
  },
  {
    name: "Skoda",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/skoda-n-logo.jpg",
  },
  {
    name: "Renault",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Renault.jpg",
  },
  {
    name: "Range Rover",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Range-Logos.jpg",
  },
  {
    name: "Porsche",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/porche.jpg",
  },
  {
    name: "Peugeot",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/peu-logo.jpg",
  },
  {
    name: "Land Rover",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/land-n-logo.jpg",
  },
  {
    name: "Ford",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Ford-N-Logo.jpg",
  },
  {
    name: "Chevrolet",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/chevrolet.jpg",
  },
  {
    name: "BMW",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/BMW-N-Logo.jpg",
  },
  {
    name: "Mini",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Mini-N.jpg",
  },
  {
    name: "Mercedes",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/mercede.jpg",
  },
  {
    name: "Mazda",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Mazda-N-Logo.jpg",
  },
  {
    name: "Kia",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/kia-n-logo.jpg",
  },
  {
    name: "Jeep",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Jeep-N-Logo.jpg",
  },
  {
    name: "Hyundai",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Hyundai-N-Logo.jpg",
  },
  {
    name: "Honda",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Honda-N-Logo.jpg",
  },
  {
    name: "Holden",
    src: "https://sidemirrorspro.com.au/wp-content/uploads/2018/06/Holden-N-Logo.jpg",
  },
];

const BeautifulTrustedSlider = () => {
  return (
    <div className="bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">
          Trusted by Leading Brands
        </h2>
        <div className="relative overflow-hidden ">
          <div className="flex animate-scroll">
            {[...brands, ...brands].map((brand, index) => (
              <div key={index} className="flex-shrink-0 w-40 mx-4 mb-10">
                <div className="bg-gray-100 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex items-center justify-center h-32">
                  <img
                    src={brand.src}
                    alt={brand.name}
                    className="max-w-full max-h-full object-contain"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeautifulTrustedSlider;
