/** @format */

import React from "react";
import HeroSectionSydney from "../../components/Cities/Sydney/HeroSectionSydney";
import IconBoxSydney from "../../components/Cities/Sydney/IconBoxSydney";
import PreviousWorkSydney from "../../components/Cities/Sydney/PreviousWorkSydney";
import AboutSydney from "../../components/Cities/Sydney/AboutSydney";

const Sydney = () => {
  return (
    <div>
      <HeroSectionSydney />
      <IconBoxSydney />
      <PreviousWorkSydney />
      <AboutSydney />
    </div>
  );
};

export default Sydney;
