/** @format */

import React, { useState } from "react";

// Simple array of image URLs
const images = [
  "https://sidecarmirrors.com.au/wp-content/uploads/2024/09/slider-5.png",
  "https://sidecarmirrors.com.au/wp-content/uploads/2024/09/slider-1.png",
  "https://sidecarmirrors.com.au/wp-content/uploads/2024/09/slider-4.png",
  "https://sidecarmirrors.com.au/wp-content/uploads/2024/09/slider-2.png",
];

// Group images into sets of two for each slide
const groupedImages = [];
for (let i = 0; i < images.length; i += 2) {
  groupedImages.push(images.slice(i, i + 2));
}

export default function PreviousWorkSydney() {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Go to the next slide, looping back to the first when at the end
  const nextSlide = () => {
    setCurrentSlide((prev) =>
      prev === groupedImages.length - 1 ? 0 : prev + 1
    );
  };

  // Go to the previous slide, looping back to the last slide when at the start
  const prevSlide = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? groupedImages.length - 1 : prev - 1
    );
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold text-center mb-8">Our Previous Work</h2>
      <div className="relative">
        {/* Render the current slide with two images */}
        <div className="flex justify-center items-center">
          {groupedImages[currentSlide].map((img, index) => (
            <div key={index} className="w-1/2 px-2">
              <img
                src={img}
                alt={`Work ${currentSlide + 1}-${index + 1}`}
                className="w-full h-auto object-cover"
              />
            </div>
          ))}
        </div>

        {/* Previous button */}
        <button
          onClick={prevSlide}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-red-500 bg-opacity-50 text-white p-2 rounded-full">
          &#10094;
        </button>

        {/* Next button */}
        <button
          onClick={nextSlide}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-red-500 bg-opacity-50 text-white p-2 rounded-full">
          &#10095;
        </button>
      </div>

      {/* Dots navigation */}
      <div className="flex justify-center mt-4">
        {groupedImages.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`h-3 w-3 rounded-full mx-1 ${
              currentSlide === index ? "bg-red-500" : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    </div>
  );
}
