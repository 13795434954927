/** @format */

import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCart } from "../components/CartContext";
import { ChevronRight, Minus, Plus, Truck, Shield } from "lucide-react";
import productsData from "../data/products.json";

const SingleProductPage = () => {
  const { id } = useParams();
  const product = productsData.find((p) => p.id === id);
  const { addToCart } = useCart();

  const [quantity, setQuantity] = useState(1);

  const handleAddToCart = () => {
    if (product) {
      addToCart(product, quantity);
      toast.success(`${product.name} added to cart!`);
    } else {
      toast.error("Product not found");
    }
  };

  if (!product) {
    return (
      <div className="container mx-auto p-6 text-center">
        <h5 className="text-2xl text-gray-700">Product not found</h5>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12 font-poppins">
      {/* Breadcrumb */}
      <nav className="flex flex-wrap items-center text-sm text-gray-500 mb-6">
        <Link to="/" className="hover:text-red-600 transition-colors">
          Home
        </Link>
        <ChevronRight className="h-4 w-4 mx-2" />
        <Link to="/shop" className="hover:text-red-600 transition-colors">
          {product.category}
        </Link>
        <ChevronRight className="h-4 w-4 mx-2" />
        <span className="font-medium text-gray-900">{product.name}</span>
      </nav>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12">
        {/* Product Image */}
        <div className="aspect-w-1 aspect-h-1 bg-gray-100 rounded-lg overflow-hidden">
          <img
            src={product.image || "/placeholder.svg"}
            alt={product.name}
            className="w-full h-full object-center object-cover"
          />
        </div>

        {/* Product Info */}
        <div className="flex flex-col">
          <h1 className="text-3xl sm:text-4xl font-semibold text-gray-900 mb-2">
            {product.name}
          </h1>
          <h2 className="text-2xl sm:text-3xl font-bold text-red-600 mb-4">
            ${product.price || "N/A"}
          </h2>
          <p className="text-gray-600 mb-6">{product.description}</p>

          {/* Quantity and Add to Cart */}
          <div className="flex flex-col sm:flex-row items-center gap-4 mb-6">
            <div className="flex items-center border border-gray-300 rounded-lg">
              <button
                className="p-2 text-gray-600 hover:text-red-600 transition-colors"
                onClick={() => setQuantity(Math.max(1, quantity - 1))}>
                <Minus className="h-5 w-5" />
              </button>
              <span className="px-4 py-2 text-gray-900">{quantity}</span>
              <button
                className="p-2 text-gray-600 hover:text-red-600 transition-colors"
                onClick={() => setQuantity(quantity + 1)}>
                <Plus className="h-5 w-5" />
              </button>
            </div>
            <button
              className="w-full sm:w-auto bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              onClick={handleAddToCart}>
              Add to Cart
            </button>
          </div>

          {/* Shipping and Warranty Info */}
          <div className="space-y-4 border-t border-gray-200 pt-6">
            <p className="flex items-center text-green-600">
              <Truck className="h-5 w-5 mr-2" />
              Free shipping on orders over $100
            </p>
            <p className="flex items-center text-gray-600">
              <Shield className="h-5 w-5 mr-2" />
              1-year warranty included
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProductPage;
