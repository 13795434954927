/** @format */

import React, { useEffect, useState } from "react";
import { Typography, Grid, Container } from "@mui/material";
import productsData from "../data/products.json";
import Trusted from "../components/Home/Trusted";
import Services from "../components/Home/Services";
import HeroSection from "../components/HeroSection/HeroSection";
import Abouts from "../components/Home/About";
import Satisfaction from "../components/Home/Satisfaction";
import ProductCard from "../components/Products/ProductCard"; // Assuming you have a ProductCard component

export default function Home() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setProducts(productsData);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#ffffff",
      }}
      className="font-poppins">
      <main style={{ flex: 1 }}>
        <HeroSection />

        <section style={{ padding: "48px", backgroundColor: "#ffffff" }}>
          <Satisfaction />
        </section>
        <section style={{ padding: "48px", backgroundColor: "#ffffff" }}>
          <Abouts />
        </section>

        <section style={{ padding: "48px 0", backgroundColor: "#fff" }}>
          <div className="max-w-[1400px] mx-auto">
            <Typography variant="h3" component="h3" gutterBottom align="center">
              Featured Products
            </Typography>

            {/* Grid for displaying 5 products per row */}
            <Grid container spacing={3} justifyContent="center">
              {products.length ? (
                products.slice(0, 12).map((product) => (
                  <Grid
                    item
                    key={product.id}
                    xs={12} // For small screens, show 1 product per row
                    sm={4} // For medium screens, show 2 products per row
                    md={4} // For larger screens, show 3 products per row
                    lg={3} // For extra large screens, show 5 products per row
                  >
                    <ProductCard product={product} />
                  </Grid>
                ))
              ) : (
                <div className="text-center py-12">
                  <h2 className="text-2xl font-semibold mb-2">
                    No products found
                  </h2>
                  <p className="text-gray-600">
                    Try adjusting your filters or search term
                  </p>
                </div>
              )}
            </Grid>
          </div>
        </section>

        <Services />
        <div className="mb-9">
          <Trusted />
        </div>
      </main>
    </div>
  );
}
