/** @format */

import React from "react";
import { DollarSign, Users } from "lucide-react";

export default function Abouts() {
  return (
    <div className="mx-auto px-4 py-2 max-w-[1400px]">
      <div className="flex flex-col md:flex-row items-center gap-8">
        <div className="md:w-1/2">
          <img
            src="https://sidecarmirrors.com.au/wp-content/uploads/2024/09/autozone-img12.png"
            alt="Red sports car"
            className="w-full h-auto rounded-lg "
          />
        </div>
        <div className="md:w-1/2">
          <h1 className="text-4xl font-bold mb-4 text-gray-800">
            Side Mirror Replacement and Repair in Australia
          </h1>
          <p className="text-lg mb-6 text-gray-600">
            Enjoy high-quality side mirror replacement and repair at the best
            prices. We offer great value and service without breaking the bank.
          </p>
          <div className="space-y-6">
            <div className="flex items-start gap-4 bg-white shadow-md p-5 rounded-md">
              <div className="bg-red-500 p-3 rounded-full">
                <DollarSign className="w-6 h-6 text-white" />
              </div>
              <div>
                <h2 className="text-xl font-semibold mb-2 text-gray-800">
                  Best Affordable Price
                </h2>
                <p className="text-gray-600">
                  We offer affordable pricing for side car mirror replacements
                  and include free installation. You get great value and
                  convenience with our services.
                </p>
              </div>
            </div>
            <div className="flex items-start gap-4 bg-white shadow-md p-5 rounded-md">
              <div className="bg-blue-800 p-3 rounded-full">
                <Users className="w-6 h-6 text-white" />
              </div>
              <div>
                <h2 className="text-xl font-semibold mb-2 text-gray-800">
                  Qualified Staff & Technician
                </h2>
                <p className="text-gray-600">
                  Our skilled staff and technicians ensure top-quality side
                  mirror replacement and repair. With their expertise, you can
                  trust that your service will be done right.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
