/** @format */

import React from "react";
import Logo from "../../assets/logo/logo-2.png";

const Footer = () => {
  return (
    <footer className="bg-[#f1f1f1]  text-gray-800 font-poppins  ">
      <div className=" max-w-[1450px] mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <img
              src={Logo}
              alt="Side Car Mirrors Logo"
              className="h-20  mb-6"
            />
            <h2 className="text-xl font-bold mb-2">
              Expert Car Mirror Solutions
            </h2>
            <p className="mb-4">
              Get your car's side mirror fixed or replaced by our skilled
              technicians. Quick and reliable service at great prices.
            </p>
            <a
              href="mailto:info@sidecarmirrors.com.au"
              className="hover:no-underline hover:font-semibold">
              <p className="text-red-600">info@sidecarmirrors.com.au</p>
            </a>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4 bg-red-600 rounded-lg text-white p-2">
              NEED HELP?
            </h3>
            <p className="text-red-600 font-bold mb-2">+61-426-902-234</p>
            <p className="mb-2">Monday - Friday: 7:00 AM- 9:00PM</p>
            <p>Saturday- Sunday: 7:00 AM- 6:00PM</p>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4 bg-red-600 rounded-lg text-white p-2">
              CITIES WE SERVE
            </h3>
            <ul>
              <a
                href="/brisbane"
                className="hover:text-red-600 hover:no-underline hover:font-bold">
                <li className="mb-2">Brisbane</li>
              </a>
              <a
                href="/sydney"
                className="hover:text-red-600 hover:no-underline hover:font-bold">
                <li className="mb-2">Sydney</li>
              </a>
              <a
                href="/gold-coast"
                className="hover:text-red-600 hover:no-underline hover:font-bold">
                <li className="mb-2">Gold Coast</li>
              </a>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4 bg-red-600 rounded-lg text-white p-2">
              OUR COMPANY
            </h3>
            <ul>
              <li className="mb-2">
                <a
                  href="/"
                  className="hover:text-red-600 hover:no-underline hover:font-bold">
                  Home
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="/shop"
                  className="hover:text-red-600 hover:no-underline hover:font-bold">
                  Shop
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="hover:text-red-600 hover:no-underline hover:font-bold">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-black text-white text-center py-4">
        <p>
          Side Car Mirrors ©{new Date().getFullYear()} | All Rights Reserved.
          Made By{" "}
          <a
            href="https://basil.marketingdressup.com/"
            className="hover:no-underline font-poppins font-bold">
            Basil
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
