/** @format */

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";
import ShopPage from "./pages/ShopPage";
import SingleProductPage from "./pages/SingleProduct";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import MakeModelYear from "./components/MMY/MakeModelYear";
import MyAccount from "./pages/MyAccount";
import Login from "./pages/Login";
import Register from "./pages/Register";
import UserProfile from "./pages/UserProfile";
import "./styles/styles.css";
import Contact from "./pages/Contact";
import About from "./pages/AboutUs";
import { CartProvider } from "./components/CartContext";
import { AuthProvider, useAuth } from "./components/AuthContext";
import ThankYouPage from "./pages/ThankYouPage";
import ProductForm from "./components/ProductForm.jsx"; // Import ProductForm component
import Brisbane from "./pages/Cities/Brisbane.jsx";
import "./index.css";
import GoldCoast from "./pages/Cities/GoldCoast.jsx";
import Sydney from "./pages/Cities/Sydney.jsx";

const ProtectedRoute = ({ element, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/login" />;
};

const App = () => (
  <AuthProvider>
    <CartProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/product/:id" element={<SingleProductPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/products" element={<MakeModelYear />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/account" element={<MyAccount />} />
          <Route path="/login" element={<Login />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/create-product" element={<ProductForm />} />
          <Route path="/brisbane" element={<Brisbane />} />
          <Route path="/gold-coast" element={<GoldCoast />} />
          <Route path="/sydney" element={<Sydney />} />
        </Routes>
        <Footer />
      </Router>
    </CartProvider>
  </AuthProvider>
);

export default App;
