/** @format */

import React, { useState, useEffect } from "react";
import { ShoppingCart, Menu as MenuIcon, Phone, X } from "lucide-react";
import { useCart } from "../CartContext"; // Assuming you have a CartContext
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/logo.jpg";

export default function Header() {
  const { cartItems } = useCart();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const toggleSubmenu = () => {
    setSubmenuOpen((prev) => !prev);
  };

  return (
    <header className="font-poppins hover:no-underline">
      {!isMobile && (
        <div className="bg-red-600 text-white">
          <div className="max-w-[1450px] mx-auto py-2 px-4 flex justify-between items-center">
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
              </svg>
              <a
                href="tel:+61-426-902-234"
                className="hover:no-underline hover:font-semibold">
                <span>+61-426-902-234</span>
              </a>
            </div>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
              <a
                href="mailto:info@sidecarmirrors.com.au"
                className="hover:no-underline hover:font-semibold">
                <span>info@sidecarmirrors.com.au</span>
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white shadow-md">
        <div className="max-w-[1450px] mx-auto py-4 px-6 flex justify-between items-center">
          {isMobile ? (
            <>
              <a
                href="tel:+61-426-902-234"
                className="hover:no-underline bg-red-700  rounded-full p-2">
                <Phone className="h-7  w-7 text-white  " />
              </a>
              <div className="flex items-center justify-center flex-grow">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="Side Car Mirrors Logo"
                    className="h-12 w-auto"
                  />
                </Link>
              </div>
              <div className="flex items-center space-x-4">
                <Link
                  to="/cart"
                  className="text-gray-800 font-bold hover:text-red-600 hover:no-underline relative">
                  <ShoppingCart className="h-6 w-6" />
                  {cartItems.length > 0 && (
                    <span className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                      {cartItems.length}
                    </span>
                  )}
                </Link>
                <button
                  onClick={handleDrawerOpen}
                  className="text-gray-800 font-bold">
                  <MenuIcon className="h-6 w-6" />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="Side Car Mirrors Logo"
                    className="h-16 w-full"
                  />
                </Link>
              </div>
              <div className="flex items-center space-x-6">
                <Link
                  to="/"
                  className="text-gray-800 font-bold hover:text-red-600 hover:no-underline">
                  Home
                </Link>
                <Link
                  to="/shop"
                  className="text-gray-800 font-bold hover:text-red-600 hover:no-underline">
                  Shop
                </Link>
                <div className="relative">
                  <button
                    onClick={toggleSubmenu}
                    className="text-gray-800 font-bold hover:text-red-600 hover:no-underline flex items-center">
                    Cities We Serve
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 ml-1"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  {submenuOpen && (
                    <div className="z-50 absolute bg-white border border-gray-200 mt-2 py-2 w-48 rounded shadow-lg">
                      <Link
                        to="/brisbane"
                        className="block px-4 py-2 text-gray-800 font-bold hover:bg-red-600 hover:text-white">
                        Brisbane
                      </Link>
                      <Link
                        to="/sydney"
                        className="block px-4 py-2 text-gray-800 font-bold hover:bg-red-600 hover:text-white">
                        Sydney
                      </Link>
                      <Link
                        to="/gold-coast"
                        className="block px-4 py-2 text-gray-800 font-bold hover:bg-red-600 hover:text-white">
                        Gold Coast
                      </Link>
                    </div>
                  )}
                </div>
                <Link
                  to="/contact"
                  className="text-gray-800 font-bold hover:text-red-600 hover:no-underline">
                  Contact
                </Link>
              </div>
              <div className="flex items-center space-x-4">
                <Link
                  to="/cart"
                  className="text-gray-800 font-bold hover:text-red-600 hover:no-underline relative">
                  <ShoppingCart className="h-6 w-6" />
                  {cartItems.length > 0 && (
                    <span className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                      {cartItems.length}
                    </span>
                  )}
                </Link>
                <a href="tel:+61-426-902-234" className="hover:no-underline">
                  <button className="bg-red-600 hover:no-underline text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300">
                    Call Now
                  </button>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
      {/* Mobile drawer menu */}
      {isMobile && drawerOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="bg-white w-64 h-full absolute right-0 p-4">
            <button onClick={handleDrawerClose} className="mb-4">
              <X className="h-6 w-6" />
            </button>
            <nav className="flex flex-col space-y-4">
              <Link
                to="/"
                className="text-gray-800 font-bold hover:text-red-600">
                Home
              </Link>
              <Link
                to="/shop"
                className="text-gray-800 font-bold hover:text-red-600">
                Shop
              </Link>
              <button
                onClick={toggleSubmenu}
                className="text-gray-800 font-bold hover:text-red-600 text-left">
                Cities We Serve
              </button>
              {submenuOpen && (
                <div className="pl-4 space-y-2">
                  <Link
                    to="/brisbane"
                    className="block text-gray-800 hover:text-red-600">
                    Brisbane
                  </Link>
                  <Link
                    to="/sydney"
                    className="block text-gray-800 hover:text-red-600">
                    Sydney
                  </Link>
                  <Link
                    to="/gold-coast"
                    className="block text-gray-800 hover:text-red-600">
                    Gold Coast
                  </Link>
                </div>
              )}
              <Link
                to="/contact"
                className="text-gray-800 font-bold hover:text-red-600">
                Contact
              </Link>
              <div className="flex items-center space-x-4">
                <Link
                  to="/cart"
                  className="text-gray-800 font-bold hover:text-red-600 hover:no-underline relative">
                  <ShoppingCart className="h-6 w-6" />
                  {cartItems.length > 0 && (
                    <span className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                      {cartItems.length}
                    </span>
                  )}
                </Link>
              </div>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
}
