/** @format */

import React from "react";
import MakeModelYear from "../../MMY/MakeModelYear";
import MakeModelYearVertical from "../../MMY/MakeModelYearHorizontal";
import FormBrisbane from "./FormGoldCoast";

const HeroSectionGoldCoast = () => {
  return (
    <section className="relative min-h-full font-poppins overflow-hidden">
      <div className="max-w-[1450px] mx-auto">
        <div
          className="absolute inset-0 bg-cover bg-center z-0"
          style={{
            backgroundImage:
              "url('https://sidecarmirrors.com.au/wp-content/uploads/2024/09/i-2.webp')",
          }}
        />
        <div className="absolute inset-0 bg-[#080809] opacity-80 z-10" />

        <div className="relative z-20 container mx-auto px-4 py-16 h-full flex items-center">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            {/* Left side content */}
            <div className="text-white">
              {/* <h2 className="text-indigo-400 font-bold text-xl mb-2">
                Welcome to
              </h2> */}
              <h1 className="text-4xl sm:text-5xl md:text-5xl font-bold mb-4 leading-tight">
                Gold Coast Side Mirror Replacement Australia
              </h1>
              <p className="text-lg sm:text-xl mb-6 max-w-xl">
                We provide side mirror replacement services in the Gold Coast
                for all vehicle types. Whether your mirror is broken or not
                working, our team will replace it with quality parts. Plus, we
                offer free installation at your home to make the process easy
                and affordable.
              </p>
              {/* <MakeModelYear />{" "} */}
            </div>

            {/* Right side image */}
            <div className="hidden lg:block">
              <div className="relative">
                {/* <div className="absolute inset-0 bg-red-600 opacity-20 transform rotate-6 rounded-3xl" /> */}
                {/* <img
                  src="https://sidecarmirrors.com.au/wp-content/uploads/2024/10/Trim_new-min.jpg"
                  alt="Mac Model Air"
                  className="relative z-10 w-full h-auto rounded-2xl shadow-2xl"
                /> */}
                <FormBrisbane />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSectionGoldCoast;
