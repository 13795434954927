/** @format */

// Checkout.jsx
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const { state } = useLocation();
  const { cartItems, shippingOption, total } = state || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (!cartItems || !shippingOption || !total) {
      navigate("/cart");
      return;
    }

    const createCheckoutSession = async () => {
      try {
        const response = await axios.post("/create-checkout-session", {
          cartItems,
          shippingOption,
          total,
        });

        const { id } = response.data;
        const stripe = window.Stripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        );

        const { error } = await stripe.redirectToCheckout({ sessionId: id });

        if (error) {
          console.error("Error redirecting to Stripe Checkout:", error);
        }
      } catch (error) {
        console.error("Error creating Checkout Session:", error);
      }
    };

    createCheckoutSession();
  }, [cartItems, shippingOption, total, navigate]);

  return <div>Loading checkout...</div>;
};

export default Checkout;
