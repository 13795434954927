/** @format */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import confetti from "canvas-confetti";
import { CheckCircle, Package, Truck, CreditCard } from "lucide-react";

const ThankYouPage = () => {
  const [sessionDetails, setSessionDetails] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get("session_id");

    fetch(`http://localhost:5000/session-status?session_id=${sessionId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setSessionDetails(data);
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        });
      })
      .catch((error) => {
        console.error("Error fetching session details:", error);
      });
  }, [location.search]);

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="max-h-[2000px] mt-36 mb-36 flex justify-center items-center bg-white p-4">
      <motion.div
        className="max-w-[800px] w-full bg-white rounded-lg shadow-xl overflow-hidden"
        initial="hidden"
        animate="visible"
        variants={fadeIn}>
        <div className="p-6 bg-gradient-to-r from-red-500 to-pink-600 text-white">
          <motion.h1
            className="text-3xl font-bold mb-2"
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}>
            Thank You for Your Purchase!
          </motion.h1>
          <CheckCircle className="w-16 h-16 mx-auto mt-4" />
        </div>

        <div className="p-6">
          {sessionDetails ? (
            <motion.div initial="hidden" animate="visible" variants={fadeIn}>
              <p className="text-lg mb-6 text-gray-600">
                A confirmation email has been sent to{" "}
                <span className="font-semibold text-indigo-600">
                  {sessionDetails.customerEmail}
                </span>
                .
              </p>
              <div className="flex justify-between items-center mb-6 text-sm text-gray-600">
                <div className="flex items-center">
                  <CreditCard className="w-4 h-4 mr-2" />
                  <span>Order Date: {sessionDetails.date}</span>
                </div>
                <div className="flex items-center">
                  <Package className="w-4 h-4 mr-2" />
                  <span>{sessionDetails.items.length} items</span>
                </div>
              </div>
              <div className="bg-gray-50 rounded-lg p-4 mb-6">
                {sessionDetails.items.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center mb-2 last:mb-0">
                    <span className="font-medium">{item.name}</span>
                    <span className="text-gray-600">
                      {item.quantity} x ${item.price}
                    </span>
                  </div>
                ))}
              </div>
              <div className="border-t pt-4">
                <div className="flex justify-between mb-2">
                  <span>Subtotal</span>
                  <span>${sessionDetails.subtotal}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="flex items-center">
                    <Truck className="w-4 h-4 mr-2" />
                    Shipping
                  </span>
                  <span>${sessionDetails.shipping}</span>
                </div>
                <div className="flex justify-between font-bold text-lg mt-4">
                  <span>Total</span>
                  <span>${sessionDetails.total}</span>
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.div
              className="flex justify-center items-center h-64"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}>
              <div className="w-16 h-16 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
            </motion.div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default ThankYouPage;
