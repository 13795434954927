/** @format */

import React, { useState, useEffect } from "react";
import { Plus, Minus, X } from "lucide-react";
import { useCart } from "../components/CartContext";
import { Link, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

export default function Cart() {
  const { cartItems, removeFromCart, updateQuantity } = useCart();
  const [email, setEmail] = useState("");
  const [shippingOption, setShippingOption] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const stripePromise = loadStripe(
    "pk_test_51Pw99QJ5eHYvRoN6BZd24jhznab2VpVrsxo0oG4dbHyzZGhK9OSC2j6ZhON9osWwlw5bpT2S0P1YeZQ2pI4KHY9a00JHQXOaAa"
  );

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    cartItems.forEach((item) => {
      if (item.quantity === undefined || item.quantity <= 0) {
        updateQuantity(item.id, 1);
      }
    });
  }, [cartItems, updateQuantity]);

  const handleQuantityChange = (id, change) => {
    const item = cartItems.find((item) => item.id === id);
    const newQuantity = (item ? item.quantity : 0) + change;
    if (newQuantity > 0) {
      updateQuantity(id, newQuantity);
    }
  };

  const handleRemoveItem = (id) => removeFromCart(id);
  const handleShippingChange = (event) => setShippingOption(event.target.value);

  const handleCheckout = async () => {
    if (!shippingOption) {
      alert("Please select a shipping option.");
    } else if (!email) {
      alert("Please enter your email address.");
    } else {
      setLoading(true);
      try {
        const stripe = await stripePromise;
        const response = await fetch(
          "http://localhost:5000/create-checkout-session",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              items: cartItems.map((item) => ({
                name: item.name,
                price: item.price,
                quantity: item.quantity,
              })),
              shippingOption,
              email,
            }),
          }
        );
        const sessionId = await response.json();
        if (!sessionId.id) {
          console.error("No session ID returned from the server.");
          return;
        }
        const result = await stripe.redirectToCheckout({
          sessionId: sessionId.id,
        });
        if (result.error) {
          console.error(result.error.message);
          navigate("/");
        }
      } catch (error) {
        console.error("Error:", error);
        navigate("/");
      } finally {
        setLoading(false);
      }
    }
  };

  const subtotal = cartItems.reduce(
    (sum, item) =>
      sum + (Number(item.price) || 0) * (parseInt(item.quantity) || 0),
    0
  );

  const shippingOptions = {
    standardTracking: 9.35,
    standardNOTracking: 7.9,
    express: 12.5,
  };

  const shippingCost = shippingOptions[shippingOption] || 0;
  const total = subtotal + shippingCost;

  return (
    <div className="bg-white mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold text-center text-black mb-8">
        Shopping Cart
      </h1>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2">
          {cartItems.length === 0 ? (
            <div className="text-center py-8 bg-white rounded-lg shadow-md">
              <p className="text-xl mb-4">Your cart is empty.</p>
              <Link to="/shop">
                <button className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-300">
                  Shop Now
                </button>
              </Link>
            </div>
          ) : (
            <div className="space-y-4">
              {cartItems.map((item) => (
                <div
                  key={item.id}
                  className="flex flex-col sm:flex-row items-center bg-white p-4 rounded-lg shadow-md">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-24 h-24 object-cover rounded mb-4 sm:mb-0 sm:mr-4"
                  />
                  <div className="flex-grow">
                    <h3 className="font-semibold">{item.name}</h3>
                    <p className="text-gray-600">
                      ${Number(item.price).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex items-center mt-4 sm:mt-0">
                    <button
                      className="p-1 bg-red-500 text-white rounded"
                      onClick={() => handleQuantityChange(item.id, -1)}
                      disabled={item.quantity === 1}>
                      <Minus className="w-4 h-4" />
                    </button>
                    <input
                      type="number"
                      value={item.quantity}
                      className="w-12 text-center border rounded mx-2"
                      onChange={(e) =>
                        updateQuantity(item.id, parseInt(e.target.value) || 1)
                      }
                    />
                    <button
                      className="p-1 bg-red-500 text-white rounded"
                      onClick={() => handleQuantityChange(item.id, 1)}>
                      <Plus className="w-4 h-4" />
                    </button>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-4 flex flex-col items-end">
                    <p className="font-semibold">
                      ${(Number(item.price) * item.quantity).toFixed(2)}
                    </p>
                    <button
                      className="text-red-500 hover:text-red-700 mt-2"
                      onClick={() => handleRemoveItem(item.id)}>
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="lg:col-span-1">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4 text-red-600">
              Cart Totals
            </h2>
            <div className="space-y-4">
              <div className="flex justify-between">
                <span>Subtotal:</span>
                <span>${subtotal.toFixed(2)}</span>
              </div>
              <div className="flex flex-col">
                <span className="mb-2">Shipping:</span>
                <select
                  className="border rounded px-2 py-1"
                  value={shippingOption}
                  onChange={handleShippingChange}>
                  <option value="">Select Shipping</option>
                  <option value="standardTracking">
                    Standard Post (Tracking) - $9.35
                  </option>
                  <option value="standardNOTracking">
                    Standard Post (No Tracking) - $7.90
                  </option>
                  <option value="express">
                    Express Post (Tracking) - $12.50
                  </option>
                </select>
              </div>
              <div className="flex flex-col">
                <span className="mb-2">Email:</span>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="border rounded px-2 py-1"
                  required
                />
              </div>
              <div className="flex justify-between font-bold text-lg">
                <span>Total:</span>
                <span>${total.toFixed(2)}</span>
              </div>
              <button
                onClick={handleCheckout}
                className={`w-full py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-300 ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}>
                {loading ? "Processing..." : "Proceed to Checkout"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
