import React, { useState, useEffect } from "react";
import axios from "axios";
import categoriesData from "../data/categories.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Box,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardMedia,
  Fade,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  background: 'linear-gradient(145deg, #f6f7f9 0%, #ffffff 100%)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const ImagePreview = styled(Card)(({ theme }) => ({
  width: '100%',
  height: 200,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
}));

const steps = ['Basic Info', 'Details', 'Image & Category'];

const ProductForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [product, setProduct] = useState({
    make: "",
    model: "",
    year: "",
    name: "",
    price: "",
    price_id: "",
    description: "",
    image: "",
    category: "",
  });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCategories(categoriesData);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "http://localhost:8000/api/products",
        product
      );
      console.log("Product created:", response.data);
      toast.success("Product created successfully!");
      setProduct({
        make: "",
        model: "",
        year: "",
        name: "",
        price: "",
        price_id: "",
        description: "",
        image: "",
        category: "",
      });
      setActiveStep(0);
    } catch (error) {
      console.error("Error creating product:", error);
      toast.error("Failed to create product. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                fullWidth
                variant="outlined"
                name="make"
                label="Make"
                value={product.make}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                fullWidth
                variant="outlined"
                name="model"
                label="Model"
                value={product.model}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                variant="outlined"
                name="year"
                label="Year"
                value={product.year}
                onChange={handleChange}
                required
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                variant="outlined"
                name="name"
                label="Product Name"
                value={product.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                fullWidth
                variant="outlined"
                name="price"
                label="Price"
                value={product.price}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                fullWidth
                variant="outlined"
                name="price_id"
                label="Price ID"
                value={product.price_id}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                name="description"
                label="Description"
                value={product.description}
                onChange={handleChange}
                required
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ImagePreview>
                {product.image ? (
                  <CardMedia
                    component="img"
                    image={product.image}
                    alt="Product Preview"
                    sx={{ objectFit: 'contain', maxHeight: '100%' }}
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No image preview available
                  </Typography>
                )}
              </ImagePreview>
              <StyledTextField
                fullWidth
                variant="outlined"
                name="image"
                label="Image URL"
                value={product.image}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  name="category"
                  value={product.category}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="">
                    <em>Select Category</em>
                  </MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Fade in={true} timeout={1000}>
        <StyledPaper elevation={3}>
          <Typography variant="h4" align="center" gutterBottom sx={{ mb: 4 }}>
            Create New Product
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <form onSubmit={handleSubmit}>
            <Box sx={{ mt: 2, mb: 4 }}>
              {renderStepContent(activeStep)}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </Button>
              <Box>
                {activeStep === steps.length - 1 ? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Create Product'}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        </StyledPaper>
      </Fade>
    </Container>
  );
};

export default ProductForm;